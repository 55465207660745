import { Component } from 'react';

import withComponentName from '../../../decorators/withComponentName';

import ButtonComponent from '../../Buttons/ButtonComponent';
import fire from '../../../assets/meetup/fire.png';
import SpinnerLoaderComponent from '../../SpinnerLoaderComponent';

import MeetupSvc from '../../../services/dbServices/MeetupSvc';

import Styles from '../styles.module.scss';

class MeetupUpcomingEventsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetups: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.getMeetups();
  }

  getMeetups() {
    MeetupSvc.getMeetup({
      startDate: new Date(),
    }).then((meetups) => {
      this.setState({
        meetups,
        loaded: true,
      });
    });
  }

  render() {
    const { meetups, loaded } = this.state;
    const { user } = this.props;

    return (
      <div className={Styles['meetup-upcoming-events-section']}>
        <div className={Styles.section}>
          <h2>
            <img src={fire} alt="" width="26" />
            UPCOMING EVENTS
          </h2>
          <p>#StayHome, Save the World, Improve Your Life.</p>
          <h6>
            <span>Join our online webinars on finance and markets</span>
            Discuss market events, test investment ideas, and learn about finance and risk.
          </h6>
          <div className={Styles['events-block']}>
            <div className={Styles.header}>
              <div className={Styles.column}>
                <span>Events</span>
              </div>
              <div className={Styles.column}>
                <span>Topics to discuss</span>
              </div>
            </div>
            {loaded ? (
              meetups.map((meetup) => (
                <div className={Styles.event} key={meetup.id}>
                  <div className={Styles.column}>
                    <span className={Styles['event-name']}>{meetup.title}</span>
                    <div className={Styles['event-status']}>
                      <span className={Styles.status} />
                      <span>{meetup.date}</span>
                    </div>
                  </div>
                  <div className={Styles['flex-column']}>
                    <div className={Styles['event-topics']}>
                      <span className={Styles.topics}>{meetup.topics_to_disscuss}</span>
                      <a href={meetup.link_to_cityfalcon_watchlist}>See Watchlist</a>
                    </div>
                  </div>
                  {Boolean(!user) && (
                  <ButtonComponent
                    clickHandler={(e) => { e.preventDefault(); }}
                    value="Register"
                    short
                    path=""
                    jqSelector=" react-header-sign login-button"
                  />
                  )}
                </div>
              ))
            ) : (
              <div className={Styles.spinner_container}>
                <SpinnerLoaderComponent />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withComponentName(MeetupUpcomingEventsSection);
